<template>
  <b-card  no-body>
    <!-- title and dropdown -->
    <b-card-header class="pb-0">
      <b-col md="6" xl="3">
        <b-form-group label-for="city">
          <label> المحافظة</label>
          <v-select
            v-model="governate"
            :reduce="(val) => val.value"
            :options="optiongovernorate"
          />
        </b-form-group>
      </b-col>
    </b-card-header>
    <!--/ title and dropdown -->

    <b-card-body>
       <b-row>
        <b-col
          v-for="(item,index)  in data"
          :key="index"
          xl="3"
          sm="6"
         
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-success"
              >
                <feather-icon
                  size="24"
                  icon="UsersIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                <label>
                {{ item.name }}
                </label>
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.value }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>

      <!-- chart info -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody, BRow, BCol, BCardText,BFormGroup,BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import vSelect from "vue-select";
export default {
  components: {
    VueApexCharts,
    vSelect,
    BFormGroup,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia, BMediaAside, BAvatar, BMediaBody,
  },

  data() {
    return {
    governate:'',
    data:[],
    optiongovernorate:[],
      label:[],

       chartOptions: {
         series:[],
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          colors:['#ffe700','#a4f8cd','#60f2ca','#2bdac7','#00d4bd','#826bf8','#2b9bf4','#FFA1A1','#a4f8cd','#60f2ca','#2bdac7','#826bf8','#2b9bf4','#FFA1A1',],
          legend: { show: true },
          comparedResult: [2, -3, 8],
          labels: [],
          stroke: { width: 0 },
          // colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.success],
          grid: {
            // padding: {
            //   right: -20,
            //   bottom: -8,
            //   left: -20,
            // },
          },
          plotOptions: {
            pie: {

              donut: {
                labels: {
                  show: true,


                  total: {
                    show: true,
                    offsetY: 15,
                    label: 'مركز السويدا',
                    formatter() {
                      return '83%  '
                    },
                  },
                },
              },
            },
          },
          responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 600,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        },

    }
  },

  watch:{
    'governate':function(){
     this.getData(this.governate)
    },
   
 },

  created(){
      let url = `/api/v1/countries/1/governorates`;
      this.$http.get(url).then((response) => {
        let governates = response.data.data;
  //console.log(response.data.data)
        governates.forEach((el) => {
          this.optiongovernorate.push({ value: el.id, label: el.name });
        });
      });
      this.getData(8)
        
  },

  methods:{
    getData(id){
        let url = `/api/v1/stats/governorates/${id}/family_statuses`;
        this.$http.get(url).then((response) => {
          //console.log(response)
          this.data=response.data.data.stats
         
        })

    }
  }
};
</script>

<style scoped>
.col-sm-6.col-xl-3 {
    padding: 13px;
}
</style>
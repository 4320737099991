<template>
  <b-card v-if="data">
       <!-- <b-card-title class="mb-sm-0 mb-1">
احصائيات الذكور والإناث في كل عام
      </b-card-title> -->
    <b-row class="pb-50">


      <!-- dropdown and chart -->
      <b-col
        
        cols="12"
        order-sm="2"
        order="1"
        class=""
      >
      <label>احصائيات الذكور والاناث في كل عام</label>
     

        <!-- apex chart -->
        <vue-apex-charts
          type="bar"
         
          :options="chartOptions"
          :series="series"
        />
      </b-col>
        <b-col
        
        cols="12"
        order-sm="2"
        order="1"
        class=""
      >
        <label>احصائيات الاطفال والشباب والمسنين  في كل عام</label>
      <vue-apex-charts
        height="500"
         ref="apexChart"
        :options="Options"
        :series="series2"
      />
        </b-col>
    </b-row>
    <hr>

    
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BDropdown, BDropdownItem, BProgress, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import { $themeColors } from '@themeConfig'
import { kFormatter } from '@core/utils/filter'
export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BButton,
    BCardText,
    BDropdown,
    BDropdownItem,
    BProgress,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      female:[],
      male:[],
     Options: {
 
          chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
           show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [],
        },
       
        fill: {
          opacity: 1
        }, tooltip: {
          
        }
       
        },
        chartOptions: {
 
          chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
           show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [],
        },
       
        fill: {
          opacity: 1
        }, tooltip: {
          
        }
       
        },
        child:[],
        young:[],
        oldperson:[]
     
    }
  },

  computed: {
      
    series() {
      let  series= [ {
          name: 'الذكور',
          data:[] ,
            color:'#826bf8'
        },
         {
          name: 'الأناث',
          data:[] ,
            color:'#FFA1A1'
        },]
      
      series[1].data = this.female;
    
      series[0].data = this.male;

      return series;
    },
       
    series2() {
      let  series= [ {
          name: 'الأطفال',
          data:[] ,
            color:'#32a89d'
        },
         {
          name: 'الشباب',
          data:[] ,
            color:'#a8327b'
        },
         {
          name: 'المسنين',
          data:[] ,
            color:'#a86632'
        },]
      
      series[0].data = this.child;
    
         series[1].data = this.young;
      series[2].data = this.oldperson;

      return series;
    }},

  watch:{
    'data':function(){
    
    let stats=[]
      this.data.forEach(el => {
        this.female.push(el.females_count)
        this.male.push(el.males_count)
        this.chartOptions.xaxis.categories.push(el.year)
         this.Options.xaxis.categories.push(el.year)
    
      stats.push(el.stats)
        
      });
   
 //console.log(stats)
  stats.forEach(el=>{
       //console.log(el)
      this.child.push(el[0].value)
      this.young.push(el[1].value)
      this.oldperson.push(el[2].value)
  })
    
 
    },



  },

  directives: {
    Ripple,
  },

  methods: {
    kFormatter,
  }
};
</script>

